import { useState } from "react";
import cn from 'classnames';

type ButtonItem<T> = {
    label: string;
    value: T;
    isVisible?: boolean;
    isEnabled?: boolean;
};

type ButtonGroupProps<T> = {
    items: ButtonItem<T>[];
    onChecked: (value: T) => void;
    initialSelected: T;
    disabled?: boolean;
    theme?: 'Light' | 'Dark';
};

export function ButtonGroup<T>({ items, onChecked, disabled = false, initialSelected, theme = 'Light' }: ButtonGroupProps<T>) {
    const [selected, setSelected] = useState(initialSelected);
    const [isOpened, setIsOpened] = useState(false);

    const getId = (value: T): string => {
        if (value == null) {
            return "ButtonGroup_null";
        }

        if (typeof value === 'object') {
            if ('trackableName' in value) {
                return "ButtonGroup_" + value.trackableName;
            }
        } else {
            if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
                return "ButtonGroup_" + value;
            }
        }
        return "ButtonGroup_" + JSON.stringify(value);
    }

    return <div className={`buttonGroupContainer buttonGroupTheme${theme}`}>
        <div className="buttonGroup d-md-none desktopButtonGroup">
            {
                items.filter(item => item.isVisible ?? true).map(item => (
                    <label title={item.label} className={cn(item.value === selected && "active", !(item.isEnabled ?? true) && "disabled")} key={JSON.stringify(item.value)} onClick={() => { !disabled && (item.isEnabled ?? true) && setSelected(item.value); !disabled && (item.isEnabled ?? true) && onChecked(item.value); }} tabIndex={0}>
                    <input
                        disabled={!(item.isEnabled ?? true)}
                        type="radio"
                        name="view"
                        checked={item.value === selected}
                        onChange={() => { }} />
                        <div id={getId(item.value)} className="firstLetterCapital">
                        {item.label}
                    </div>
                </label>))
            }
        </div>

        {isOpened ?
            <div className="buttonGroup d-none d-md-flex mobileButtonGroup">
                {
                    items.filter(item => item.isVisible ?? true).map(item => (
                        <label title={item.label} className={cn(!(item.isEnabled ?? true) && "disabled", item.value === selected && "active")} onClick={() => { !disabled && (item.isEnabled ?? true) && setSelected(item.value); if (!disabled && (item.isEnabled ?? true) && item.value !== selected) onChecked(item.value); setIsOpened(false) }} tabIndex={0} key={JSON.stringify(item.value)}>
                            <input
                                type="radio"
                                name="view-md"
                                disabled={!(item.isEnabled ?? true)}
                                checked={item.value === selected}
                                onChange={() => { }} />
                            <div id={getId(item.value)} className="firstLetterCapital">
                                {item.label}
                            </div>
                        </label>))
                }
            </div>
            :
            <div className="buttonGroup d-none d-md-flex mobileButtonGroup mobileButtonGroupClosed">
                {
                    items.filter(item => (item.isVisible ?? true) && item.value === selected)
                        .map(item => <label tabIndex={0} title={item.label} className={cn(!(item.isEnabled ?? true) && "disabled")} onClick={() => !disabled && (item.isEnabled ?? true) && setIsOpened(true)} key={JSON.stringify(item.value) + "-selected"}>
                        <input
                            type="radio"
                            name="view-md-selected"
                            disabled={!(item.isEnabled ?? true)}
                            checked={item.value === selected}
                            onChange={() => { }} />
                        <div className="firstLetterCapital">
                            {item.label}
                        </div>
                    </label>)
                }
            </div>
            }
    </div>;
}